import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "contact-us"
};
import ContactUsBanner from "@/views/customer_service/contact-us-module/contact-us-banner.vue";
import ServiceHotline from "@/views/customer_service/contact-us-module/service-hotline.vue";
import ContactForm from "@/views/customer_service/contact-us-module/contact-form.vue";
export default {
  __name: 'contact-us',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(ContactUsBanner), _createVNode(ServiceHotline), _createVNode(ContactForm)]);
    };
  }
};